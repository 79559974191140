.base-card{
    background: #FFFFFF;
    box-shadow: 0px 2px 8px rgba(72, 72, 72, 0.25);
    border-radius: 4px;
    margin-top: 18px !important;
    padding: 10px 16px 10px 16px;
}

.base-card > .title-wrapper{
    display: flex;
    align-items: center;
    justify-content: flex-start;
    height: 18px;
    margin-top: 12px;
    margin-bottom: 12px;
}

.card-icon{
    width: 22px;
}

.base-card > .title-wrapper > .title{
    margin-left: 6px;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 18px;
    /* identical to box height */


    color: #000000;

}
.base-card > .content {
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 15px;
    color: #666666;
    margin-top: 4px;
    margin-bottom: 11px;
}