.cc-stepper-container {
    display: flex;
    width: 100%;
}

.cc-stepper-container > * {
    flex: 1 1 0px;
}

.cc-stepper-step > * {
    display: inline-block
}

.cc-stepper-bar {
    width: calc(50% - 8px);
    height: 2px;
    margin-bottom: 6px;
}

.cc-stepper-bar.active {
    background-color: #3330E8;
}

.cc-stepper-bar.hidden {
    background-color: transparent;
}

.cc-stepper-bar.disabled {
    background-color: #E9EAEC;
}

.cc-stepper-dot {
    width: 16px;
    height: 16px;
    margin-top: 4px;
    border-radius: 50%;
}

.cc-stepper-dot.active {
    border: 4px solid #3330E8;
    background-color: white;
}

.cc-stepper-dot.disabled {
    border: 4px solid white;
    background-color: #E9EAEC;
}
