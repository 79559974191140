.vcard-container {
    border: 2px solid #E9EAEC;
    padding: 0.5rem;
    display: flex;
    flex-direction: row;
}

.vcard-container > div {
    width: 50%;
}

.vcard-image {
    width: 100%;
    max-height: 7rem;
    object-fit: contain;
    overflow: hidden;
}

.vcard-text-container {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.vcard-pretitle {
    font-size: 0.9rem;
    margin: 0;
    overflow: hidden;
}

.vcard-title {
    font-size: 1.1rem;
    font-weight: bold;
    margin: 0;
    overflow: hidden;
}

.vcard-subtitle {
    font-size: 0.9rem;
    margin: 0;
    overflow: hidden;
}