.cc-dropdown-header {
    cursor: pointer;
    border-bottom: 2px solid #E9EAEC;
    display: flex;
    justify-content: space-between;
    margin: 1rem 0 0 0;
    padding: 1rem 0.5rem 1rem 0;
}

.cc-dropdown-header * {
    pointer-events: none;
    display: inline-block;
    user-select: none;
}

.cc-dropdown-body {
    padding: 1rem 0;
}

.cc-dropdown-header .cc-dropdown-button::before {
	border-style: solid;
	border-width: 2px 2px 0 0;
	content: '';
	display: inline-block;
	height: 0.7rem;
	width: 0.7rem;
	left: 0.1rem;
	top: 0.35rem;
	position: relative;
	transform: rotate(-45deg);
	vertical-align: top;
    color: #2f2f2f;
    border-radius: 0.1rem;
}

.cc-dropdown-header .cc-dropdown-button.bottom:before {
	transform: rotate(135deg);
}