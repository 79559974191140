.accordion-cc,
.accordion-cc .accordion-item,
.accordion-cc .accordion-item .accordion-header,
.accordion-cc .accordion-item .accordion-header button {
    border: none;
    box-shadow: none;
}

.accordion-cc .accordion-item .accordion-header button {
    border-bottom: 1px solid #E2E2E2;
}

.accordion-cc .accordion-item .accordion-header button:not(.collapsed),
.accordion-cc .accordion-cc-button:focus {
    background-color: transparent !important;
    box-shadow: none;
}

.accordion-cc .accordion-item .accordion-header button::after {
    background: url(../../assets/icons/minus.svg) no-repeat 50% 50%;
    transform: none;
}

.accordion-cc .accordion-item .accordion-header button.collapsed::after {
    background: url(../../assets/icons/plus.svg) no-repeat 50% 50%;
}

.accordion-cc .accordion-item .accordion-header svg {
    width: 34px;
    height: 34px;
}
