.sc-container {
    border-radius: 2rem;
    overflow: hidden;
    position: relative;
}

.sc-container .sc-header {
    height: 15rem;
    position: relative;
}

.sc-container > * {
    overflow: hidden;
}

.sc-container .sc-header .sc-header-content {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    text-align: center;
}

.sc-container .sc-header .sc-header-content img {
    bottom: 40%;
    left: 50%;
    max-height: 80%;
    max-width: 80%;
    position: absolute;
    transform: translate(-50%,50%);
}

.sc-container .sc-header .sc-header-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
}

.sc-container .sc-header .sc-header-title {
    font-size: 1.7rem;
    line-height: 1;
    font-weight: bold;
    margin: 0;
    color: white;
}

.sc-container .sc-header .sc-header-subtitle {
    font-size: 1.2rem;
    margin: 0;
}

.sc-container .sc-header .sc-header-background {
    height: 100%;
}

.sc-container .sc-header .sc-header-background .sc-header-background-top {
    padding: 1.5rem;
    overflow: hidden;
    height: 70%;
}

.sc-container .sc-header .sc-header-background .sc-header-background-bottom {
    height: 30%;
}

.sc-container .sc-body {
    padding: 0 1.5rem 1.5rem 1.5rem;
    overflow: hidden;
}

.sc-container .sc-ribbon {
    position: absolute;
    background: #00D880;
    box-shadow: 0 0 0 999px #00D880;
    clip-path: inset(0 -100%);
    font-weight: 600;
    font-size: 14px;
    padding: 0.2rem;
    inset: 0 auto auto 0;
    transform-origin: 100% 0;
    transform: translate(-29.3%) rotate(-45deg);
}
