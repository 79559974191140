.select-div {
    position: relative;
}

.select-div .select-input {
    display: block;
    width: 100%;
    border: 2px solid lightgray;
    padding: 1rem;
    font-size: 1rem;

    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
}

.select-div:after {
    content: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' fill='2D2E34' class='bi bi-chevron-down' viewBox='0 0 16 16'%3E%3Cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3E%3C/svg%3E");
    right: 11px;
    top: 6px;
    height: 34px;
    padding: 15px 0px 0px 8px;
    position: absolute;
    pointer-events: none;
}

.select-input:invalid {
    color: #8F8F8F;
  }