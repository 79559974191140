.loader-container.fullscreen {
    position: fixed;

    height: 100vh;
    width: 100vw;
    background-color: white;
}

.loader-container .loader-center {
    position: relative;
    text-align: center;
}

.loader-container.fullscreen .loader-center {
    transform: translateY(-50%);
    top: 50%;
}

@keyframes spin {
    0% {
        transform: rotate(45deg);
    }
    50% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(-45deg);
    }
}

.loader-container .loader-icon {
    animation-name: spin;
    animation-duration: 1000ms;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
    animation-direction: alternate;
    width: 3rem;
    height: 3rem;
}

.loader-container.fullscreen p {
    margin-top: 0.2rem;
    font-size: 1.3rem;
}
