.layout-container {
    display: flex;
    flex-direction: row;
}

.menu-column {
    width: 25vw;
    top: 0;
    vertical-align: top;
    position: sticky;
    align-self: flex-start
}

.content-column {
    display: inline-block;
    width: 100%;
}

.content-container {
    background: white;
    border-radius: 2rem;
    min-height: calc(100vh - 3rem);
    padding: 1.5rem 3rem;
}

@media only screen and (max-width: 767px) {
    .layout-container {
        display: block;
    }
    
    .menu-column {
        width: 100vw;
        z-index: 100000;
    }

    .content-column {
        width: 100vw;
        padding: 0;
    }

    .content-container {
        padding: 1rem;
    }
}