.sq-container {
    position: relative;
    letter-spacing: 2px;
    background-color: lightgray;
    border: 2px solid lightgray;
    padding-top: 80%;
}

.sq-container:hover {
    opacity: 0.7;
}

.sq-container .sq-content {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 75%;
    height: 75%;
}

.sq-container .sq-content img,
.sq-container .sq-content svg {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    height: 100%;
}
