.menu {
    width: 100%;
    max-height: 100vh;
    position: sticky;
    background: #F5F5F5;
}


/* Hide scrollbar for Chrome, Safari and Opera */
.menu *::-webkit-scrollbar {
    display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.menu * {
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
}

.menu .menu-header {
    width: 100%;
    text-align: center;
    padding: 1rem 0;
}

.menu .menu-header .menu-brand {
    height: 3rem;
    max-width: 90%;
}

.menu .menu-header .menu-button {
    display: none;
    height: 3rem;
    width: 3rem;
    justify-content: space-between;
    flex-direction: column;
    padding: .7rem .5rem;
    background: none;
    border: none;
}

.menu .menu-header .menu-button > span {
    display: block;
    height: 4px;
    width: 100%;
    background-color: blue;
    border-radius: 3rem;
    pointer-events: none;
}

@media only screen and (max-width: 767px) {
    .menu {
        display: block;
        min-height: unset;
        padding: 0 0.5rem;
    }

    .menu .menu-header {
        display: flex;
        justify-content: space-between;
        text-align: left;
    }

    .menu .menu-header .menu-button {
        display: inline-flex;
    }
}

.menu .menu-header .menu-button,
.menu .menu-header .menu-button > span {
    transition: all .2s ease-in-out;
}

.menu .menu-header .menu-button.cross {
    transition-delay: .4s;
    transform: rotate(45deg);
}

.menu .menu-header .menu-button.cross > span:nth-child(2) {
    width: 0;
}

.menu .menu-header .menu-button.cross > span:first-child,
.menu .menu-header .menu-button.cross > span:last-child {
    transition-delay: .2s;
}

.menu .menu-header .menu-button.cross > span:first-child {
    transform: translateY(.67rem);
}

.menu .menu-header .menu-button.cross > span:last-child {
    transform: translateY(-.67rem) rotate(90deg);
}

.menu .menu-body {
    width: 100%;
    background-color: transparent;
    z-index: 10001;
    height: calc(100vh - 80px);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 0 1rem;
}

@media only screen and (max-width: 767px) {
    .menu .menu-body {
        position: absolute;
        background-color: #F5F5F5;
        left: 0;
        height: unset;
        max-height: 0;
        transition: max-height .15s ease-out;
        overflow: hidden;
    }

    .menu .menu-body.open {
        max-height: calc(100vh - 80px);
        transition: max-height .3s ease-in;
        overflow-y: scroll;
    }

    .menu .menu-body-overlay {
        z-index: 10000;
        position: absolute;
        background-color: rgba(0, 0, 0, .5);
        top: 5rem;
        left: 0;
        right: 0;
        opacity: 0;
        transition: opacity .15s ease-out;
    }

    .menu .menu-body-overlay.open {
        height: calc(100vh - 5rem);
        opacity: 1;
        transition: opacity .2s ease-in;
    }
}

.menu .menu-body .menu-body-link {
    display: block;
    color: #333333;
    font-size: 0.9rem;
    text-decoration: none;
    padding: .7rem 1rem;
    margin-top: 0.5rem;
    background: none;
    width: 100%;
    border: 1px solid transparent;
    text-align: left;
    position: relative;
}

.menu .menu-body .menu-body-link:hover {
    border: 1px solid #E1E1E1;
    border-radius: 100rem;
}

.menu .menu-body .menu-body-link.active:hover {
    font-weight: normal;
}

.menu .menu-body .menu-body-link.active {
    color: #3330E8;
    text-decoration: none;
    border: 1px solid #131353;
    border-radius: 100rem;
}

.menu .menu-body .menu-body-link.active > svg {
    color: #00D880;
}

.menu .menu-body .menu-body-link.center {
    text-align: center;
}

.menu .menu-body .menu-body-link.left {
    text-align: left;
}

.menu .menu-body .menu-body-link.right {
    text-align: right;
}

.menu .menu-body .menu-body-link > svg {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    width: 16px;
    height: 16px;
}

.menu .menu-body .menu-body-link > svg~* {
    padding-left: 1.5rem;
    display: inline-block;
}
