.rounded-modal .modal-content {
    border-radius: 2rem;
    border: none;
}

.rounded-modal .icon-container {
    margin: 2rem 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
}