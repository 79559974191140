.empty-state-container:not(.block) {
    position: absolute;
    text-align: center;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.empty-state-container.block {
    text-align: center;
}

.empty-state-container img {
    max-width: 80vw;
    max-height: 30vh;
}
