.table-cc {
    width: 100%;
}

.table-cc thead tr th {
    font-size: 0.8rem;
}

.table-cc tbody tr td {
    font-size: 0.9rem;
}

.table-cc tbody tr:nth-child(odd) {
    background-color: #F5F5F5;
}

.table-cc thead tr th,
.table-cc tbody tr td {
    padding-top: 1rem;
    padding-bottom: 1rem;
}

.table-cc thead tr th:first-child,
.table-cc tbody tr td:first-child {
    padding-left: 2rem;
}
