.base-button:disabled,
.base-button.disabled {
    opacity: 0.5;
    pointer-events: none;
}

.base-button {
    padding: 0.7rem 2rem;
}

@media only screen and (max-width: 767px) {
    .base-button {
        padding: 0.7rem 0.5rem;
    }
}

a.base-button {
    text-decoration: none;
    display: inline-block;
    text-align: center;
}

a.base-button.inline {
    padding: 0 !important;
    display: initial;
}