.tab-bar-controller {
    display: flex;
    justify-content: flex-start;
    overflow-x: scroll;
}

/* Hide scrollbar for Chrome, Safari and Opera */
.tab-bar-controller::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.tab-bar-controller {
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}

.tab-bar-controller > div:last-child {
    flex-grow: 1;
    border-bottom: 1px solid #E1E1E1;
}

.tab-bar-controller-tab {
    box-sizing: border-box;
    background: none;
    padding: 20px 16px 22px 16px;
    border: 1px solid transparent;
    border-bottom: 1px solid #E1E1E1;
    min-width: 10vw;
    position: relative;
}

.tab-bar-controller-tab.active {
    border-bottom: none;
}

.tab-bar-controller-tab.active > div {
    height: 3px;
    background-color: #3330E8;
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    border-radius: 2px;
}

@media only screen and (max-width: 767px) {
    .tab-bar-controller-tab {
        padding: 10px 10px;
        min-width: 40vw;
    }
}