.circular-number{
    /*style={{ width:'20px', height:'20px', borderRadius: '50%', backgroundColor: 'black', color: 'white' }}*/
    width: 20px;
    height: 20px;
    border-radius: 50%;
    background-color: black;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 12px;
}

.acc-subtitle{
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 15px;
    color: #000000;
    width: 100%;

}
.acc-subtitle-centered{
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 15px;
    color: #000000;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}
.buttons-container{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 18px;
    margin-bottom: 35px;
}

.green-ticket{
    width: 20px;
    height: 20px;
    margin-right: 10px;
}

.gray-ticket{
    width: 20px;
    height: 20px;
    margin-right: 10px;
    filter: grayscale(100%);
}

.showAlert{
    visibility: visible;
    opacity: 1;
}
.hideAlert{
    visibility: hidden;
    opacity: 0;
    transition: visibility 0s, opacity 0.5s linear;
}