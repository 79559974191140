.cc-checkbox-input {
    margin-right: 0.5rem !important;
    display: inline-block;
}

.cc-checkbox-label {
    font-size: 0.9rem;
    display: inline-block;
}

.cc-checkbox-label a {
    font-size: 0.9rem;
    font-weight: bold;
    color: #003CA6;
    text-decoration: none;
}