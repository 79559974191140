.auth-form-group {
    margin: 1rem 0;
}

.auth-form-group label {
    color: #131353;
    font-size: 14px;
    margin: 1rem 0 0.5rem 0;
}

.auth-form-group input {
    border: 1px solid #3330E8;
    border-radius: 10px;
    width: 100%;
    padding-left: 1rem;
    margin: 0;
    height: 3rem;
}

.auth-form-group input:focus,
.auth-form-group button:focus {
    outline: none;
}

.auth-form-group input:disabled {
    cursor: not-allowed;
}

.auth-form-group .input-button {
    display: flex;
}

.auth-form-group .input-button input {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    border-right: none;
}

.auth-form-group .input-button input~button {
    border: 1px solid #3330E8;
    border-radius: 10px;

    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    border-left: none;

    height: 3rem;
    width: 3rem;
    background: none;
    padding: 0.9rem;

    background-repeat: no-repeat;
    background-size: contain;
    background-origin: content-box;
}

.auth-form-group .input-button input[type=password]~button {
    background-image: url('../../assets/icons/eye-open-icon.svg');
}

.auth-form-group .input-button input[type=text]~button {
    background-image: url('../../assets/icons/eye-closed-icon.svg');
}

.auth-form-validator > svg {
    margin-right: 1.5rem;
}

.auth-form-validator > svg.unset {
    color: #131353;
}

.auth-form-validator > svg.active {
    color: #00D880;
}

.auth-form-validator > svg.error {
    color: red;
}

.auth-form button[type=submit] {
    background-color: #00D880;
    border: none;
    color: #131353;
    font-weight: bold;
    border-radius: 100rem;
    width: 100%;
    padding: 1rem 0;
}

.auth-form button[type=submit]:disabled {
    opacity: 0.5;
}

.auth-form .auth-form-links {
    text-align: right;
}

.auth-form .auth-form-links button,
.auth-form .auth-form-links a {
    background: none;
    border: none;
    color: #3330E8;
    text-decoration: none;
    font-weight: bold;
}

.auth-form .auth-form-links button:hover,
.auth-form .auth-form-links a:hover {
    color: #1f1d86;
}

.auth-back-button {
    color: #131353;
    font-weight: bold;
    background: none;
    border: none;
    padding-left: 2rem;

    background-repeat: no-repeat;
    background-size: contain;
    background-image: url('../../assets/icons/arrow-right-icon.svg');
}