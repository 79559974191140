.sq-container-grid {
    display: inline-grid;
    width: 100%;
    grid-template-columns: repeat(7, 9%);
    grid-gap: 20px;
}

@media only screen and (max-width: 767px) {
    .sq-container-grid {
        grid-template-columns: repeat(3, 25%);
        justify-content: space-between;
    }
}