.custom-toast {
    position: fixed;
    top: 1rem;
    right: 1rem;
    z-index: 10000;
    max-width: 30vw;
    border-radius: 1rem;
    padding: 0.5rem 1rem;

    padding-right: calc(20px + 1.5rem);
}

.custom-toast p {
    margin: 0
}

@media only screen and (max-width: 767px) {
    .custom-toast {
        max-width: 100vw;
        top: 1rem;
        right: 1rem;
        left: 1rem;
    }
}

.custom-toast .close-button {
    height: 20px;
    width: 20px;
    background: none;
    border: none;
    position: absolute;
    top: 0.5rem;
    right: 1rem;
}

.custom-toast .close-button > svg {
    height: 20px;
    width: 20px;
    position: absolute;
    top: 0;
    right: 0;
}