.ws-button {
	background-color: #25d366;
	color: #ffffff;
    text-decoration: none;
}

.ws-button:hover {
	color:#ffffff;
}

.ws-button.fixed:hover {
	box-shadow: 0 0 5px #999;
}

.ws-button > .spacer {
    width: 0.5rem;
    display: inline-block;
}

.ws-button.fixed {
    position: fixed;
    bottom: 2rem;
    right: 2rem;
    border-radius: 2rem;
    height: 4rem;
    width: 4rem;

    z-index: 100;

    padding: 1rem;
	box-shadow: 0 0 3px #999;
}

.ws-button.fixed > svg {
    height: 2rem;
    width: 2rem;
}

.ws-button.inline {
    position: unset;
    display: inline-block;

    border-radius: 1rem;
    padding: 0.5rem 1rem;
}

.ws-button.inline > svg {
    height: 1rem;
    width: 1rem;
}

@media only screen and (max-width: 768px) {
    .ws-button.fixed {
        right: 0;
        left: auto;
        bottom: 2rem;
        width: 3.5rem;
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
        border-left: none;
        text-align: center;
        padding: 1rem 0 1rem 0.5rem;
    }
}
